// @ts-nocheck
import { Link, useParams } from 'react-router-dom';
import styles from './PaymentStatus.module.scss';
import { Button } from '@shared/Button';
import { useSelector } from 'react-redux';

export const PaymentStatus = () => {
  const { user } = useSelector((state) => state.auth);
  const params = useParams();

  const paymentId = location.hash;

  return (
    <div className={styles.payment}>
      <h1>Платеж {paymentId}</h1>
      <div className={styles.status}>
        Статус платежа: {params.status === 'success' ? 'успешно.' : 'отклонен.'}
      </div>
      <div className={styles.footer}>
        <Link to='/lead/services/'>
          <Button>{user ? 'Вернуться на платформу' : 'Войти на платформу'}</Button>
        </Link>
      </div>
    </div>
  );
};
