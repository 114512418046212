import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    defaultColors: {
      white: '#FFFFFF',
      main: '',
    },
    primary: {
      lighter: '#DCE2FF',
      light: '#9EABFF',
      main: '#394ECA',
      dark: '#1D2D91',
      darker: '#151C4B',
      transparent_8: '#394ECA14',
      transparent_12: '#394ECA1F',
      transparent_16: '#394ECA29',
      transparent_20: '#394ECA33',
      transparent_24: '#394ECA3D',
      transparent_32: '#394ECA52',
      transparent_48: '#394ECA7A',
      transparent_80: '#394ECADB',
    },
    secondary: {
      main: '#dc004e', // вторичный цвет
    },
    success: {
      lighter: '#DCE2FF',
      light: '#77ED8B',
      main: '#22C55E',
      dark: '#118D57',
      darker: '#015B45',
      transparent_8: '#22C55E14',
      transparent_12: '#22C55E1F',
      transparent_16: '#22C55E29',
      transparent_20: '#22C55E33',
      transparent_24: '#22C55E3D',
      transparent_32: '#22C55E52',
      transparent_48: '#22C55E7A',
      transparent_80: '#22C55EDB',
    },
    bonus: {
      lighter: '#EFD6FF',
      light: '#C684FF',
      main: '#8E33FF',
      dark: '#5119B7',
      darker: '#27097A',
      transparent_8: '#8E33FF14',
      transparent_12: '#8E33FF1F',
      transparent_16: '#8E33FF29',
      transparent_20: '#8E33FF33',
      transparent_24: '#8E33FF3D',
      transparent_32: '#8E33FF52',
      transparent_48: '#8E33FF7A',
      transparent_80: '#8E33FFDB',
    },
    info: {
      lighter: '#D5E4FE',
      light: '#81ADFD',
      main: '#2D77FC',
      dark: '#1B4798',
      darker: '#123065',
      transparent_8: '#2D77FC14',
      transparent_12: '#2D77FC1F',
      transparent_16: '#2D77FC29',
      transparent_20: '#2D77FC33',
      transparent_24: '#2D77FC3D',
      transparent_32: '#2D77FC52',
      transparent_48: '#2D77FC7A',
      transparent_80: '#2D77FCDB',
    },
    warning: {
      lighter: '#FFF5CC',
      light: '#FFD666',
      main: '#FFAB00',
      dark: '#B76E00',
      darker: '#733D00',
      transparent_8: '#FFAB0014',
      transparent_12: '#FFAB001F',
      transparent_16: '#FFAB0029',
      transparent_20: '#FFAB0033',
      transparent_24: '#FFAB003D',
      transparent_32: '#FFAB0052',
      transparent_48: '#FFAB007A',
      transparent_80: '#FFAB00DB',
    },
    error: {
      lighter: '#FFECEC',
      light: '#FFB2B2',
      main: '#FE3C3C',
      dark: '#BC201B',
      darker: '#7A0916',
      transparent_8: '#FE3C3C14',
      transparent_12: '#FE3C3C1F',
      transparent_16: '#FE3C3C29',
      transparent_20: '#FE3C3C33',
      transparent_24: '#FE3C3C3D',
      transparent_32: '#FE3C3C52',
      transparent_48: '#FE3C3C7A',
      transparent_80: '#FE3C3CDB',
    },
    greyColor: {
      grey_100: '#F7F8FB',
      grey_200: '#E2E3E8',
      grey_300: '#CCCED5',
      grey_400: '#B7B9C2',
      grey_500: '#A1A4AF',
      grey_600: '#77798A',
      grey_700: '#4C4F64',
      grey_800: '#21253E',
      grey_900: '#1A1E32',
      transparent_8: '#77798A14',
      transparent_12: '#77798A1F',
      transparent_16: '#77798A29',
      transparent_20: '#77798A33',
      transparent_24: '#77798A3D',
      transparent_32: '#77798A52',
      transparent_48: '#77798A7A',
      main: '',
    },
    textColor: {
      primary: '#21253E',
      secondary: '#77798A',
      main: '',
      error: '#FE3C3C',
      primary_dark: '#1D2D91',
      white: '#FFFFFF',
      black: '#3F4049',
      darkGrey: '#6F7283',
      softGrey: '#AFB3CB',
    },
  },
  typography: {
    h1: {
      fontSize: 32,
      fontWeight: 800,
      lineHeight: '40px',
    },
    h2: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: '32px',
    },
    h3: {
      fontSize: 22,
      fontWeight: 600,
      lineHeight: '30px',
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '28px',
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '26px',
    },
    h6: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
    },
    subtitle1: {
      fontSize: 15,
      fontWeight: 500,
      lineHeight: '22px',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '22px',
    },
    body1: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '22px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '22px',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: 0,
    },
    overline: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '18px',
    },
    button: { textTransform: 'none' },
    mobile_h1: {
      fontSize: 24,
      fontWeight: 800,
      lineHeight: '32px',
    },
    mobile_h2: {
      fontSize: 22,
      fontWeight: 700,
      lineHeight: '30px',
    },
    mobile_h3: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '28px',
    },
    mobile_h4: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: '28px',
    },
    mobile_h5: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '26px',
    },
    mobile_h6: {
      fontSize: 15,
      fontWeight: 600,
      lineHeight: '24px',
    },
  },
  components: {
    MuiTypography: {
      variants: [
        { props: { variant: 'h6' }, style: { fontSize: 16, fontWeight: 600, lineHeight: '24px' } },
      ],
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          transformOrigin: 'center',
          transform: 'rotate(0deg) !important',

          '&.Mui-expanded': {
            transform: 'rotate(90deg) !important',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: 'inherit', // Сбросить цвет фона
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: ownerState.color === 'primary' ? 'white' : 'default',
          backgroundColor: ownerState.color === 'primary' ? '#21253E' : 'transparent',
          '&:hover': {
            backgroundColor: ownerState.color === 'primary' ? '#4C4F64' : 'rgba(0, 0, 0, 0.04)',
          },
        }),
        sizeSmall: {
          width: '36px',
          height: '36px',
        },
      },
    },
  },
});
