import { configureStore } from '@reduxjs/toolkit';

import authSlice from './auth/auth';
import businessCommunitySlice from '@store/businessCommunity/businessCommunity';
import calendarSlice from '@store/calendar/calendar';
import clubSlice from '@store/club/club';
import clubInviteSlice from './clubInvite/clubInvite';
import commonSlice from './common/common';
import communityPageSlice from './communityPage/communityPage';
import communityMembersSlice from './communityPage/membersStore/membersStore';
import communityTeamSlice from '@store/communityPage/teamStore/teamStore';
import childCommunitySlice from '@store/communityPage/childCommunityStore/childCommunityStore';
import competenceSlice from '@store/competence/competence';
import completionSlice from '@store/completion/completion';
import feedbackSlice from './feedback/feedback';
import interviewSlice from '@store/interview/interview';
import karmaSlice from './karma/karma';
import landingSlice from './landing/landing';
import messengerSlice from '@store/messenger/messenger';
import notificationsSlice from './notification/notifications';
import profilePersonalSlice from '@store/personal/personal';
import profileEducationsSlice from '@store/profileEducations/profileEducations';
import profileJobsSlice from '@store/profileJobs/profileJobs';
import profileProjectsSlice from '@store/profileProjects/profileProjects';
import profileSlice from './profile/profile';
import profileUISlice from '@store/profileUI/profileUI';
import recommendFormSlice from '@store/services/hrsm/recommendFormSlice';
import registrationSlice from '@store/registration/registration';
import resourceCardSlice from '@store/club/resourceCard';
import securitySlice from '@store/security/secuity';
import servicesSlice from '@store/services/services';
import subscriptionSlice from '@store/subscription/subscription';
import systemSlice from '@store/system/system';
import telegramBotSlice from '@store/telegram/telegram';
import vacancySlice from '@store/services/hrsm/vacancySlice';
import verificationSlice from '@store/verification/verification';
import uiSlice from '@store/ui/ui';

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    businessCommunity: businessCommunitySlice.reducer,
    calendar: calendarSlice.reducer,
    club: clubSlice.reducer,
    clubInvite: clubInviteSlice.reducer,
    common: commonSlice.reducer,
    communityPage: communityPageSlice.reducer,
    communityMembers: communityMembersSlice.reducer,
    communityTeam: communityTeamSlice.reducer,
    childCommunity: childCommunitySlice.reducer,
    competence: competenceSlice.reducer,
    completion: completionSlice.reducer,
    feedback: feedbackSlice.reducer,
    interview: interviewSlice.reducer,
    karma: karmaSlice.reducer,
    landing: landingSlice.reducer,
    messenger: messengerSlice.reducer,
    notifications: notificationsSlice.reducer,
    personal: profilePersonalSlice.reducer,
    profile: profileSlice.reducer,
    profileEducations: profileEducationsSlice.reducer,
    profileJobs: profileJobsSlice.reducer,
    profileProjects: profileProjectsSlice.reducer,
    profileUI: profileUISlice.reducer,
    recommendForm: recommendFormSlice.reducer,
    registration: registrationSlice.reducer,
    security: securitySlice.reducer,
    services: servicesSlice.reducer,
    subscription: subscriptionSlice.reducer,
    system: systemSlice.reducer,
    verification: verificationSlice.reducer,
    vacancy: vacancySlice.reducer,
    resourceCard: resourceCardSlice.reducer,
    telegramBot: telegramBotSlice.reducer,
    ui: uiSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
