import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '@utils/axios';

export const PayOrder = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();

  const init = async () => {
    const { data } = await axios.get(`/payments/${orderId}/proceed`);
    if (!data) {
      return navigate(`/lead/services`);
    }
    window.open(data.paymentUrl, '_top');
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Box sx={{ padding: 10, textAlign: 'center' }}>
      <CircularProgress />
    </Box>
  );
};
