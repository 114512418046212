import { useSelector } from 'react-redux';

import { createTheme, useTheme } from '@mui/material/styles';
import { RootState } from '@store/index';

export const useOverrideTheme = () => {
  const defaultTheme = useTheme();
  const { pageData } = useSelector((state: RootState) => state.communityPage);

  const isCommunityPage = window.location.pathname.includes('community');

  const theme = createTheme({
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      primary: {
        ...defaultTheme.palette.primary,
        main: pageData.bgColor,
        transparent_8: `${pageData.bgColor}14`,
        transparent_12: `${pageData.bgColor}1F`,
        transparent_16: `${pageData.bgColor}29`,
        transparent_80: `${pageData.bgColor}DB`,
      },
    },
    components: {
      ...defaultTheme.components,
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              backgroundColor: `${pageData.bgColor}29`,
            },
          },
        },
      },
    },
  });

  return isCommunityPage && pageData.bgColor ? theme : defaultTheme;
};
